import React from 'react';
import { Route, Routes } from 'react-router-dom';

import BenefitsDiscoveryPost from './posts/BenefitsDiscoveryPost';
import BenefitsPWAPost from './posts/BenefitsPWAPost';
import ITAppsPost from './posts/ITAppsPost';
import ITvsAppsSupportPost from './posts/ITvsAppsSupportPost';
import MobileAppDevelopmentPost from './posts/MobileAppDevelopmentPost';
import OffshoreProblemsPost from './posts/OffshoreProblemsPost';
import ReactiveProactivePost from './posts/ReactiveProactivePost';
import ShouldBuildMobileAppPost from './posts/ShouldBuildMobileAppPost';
import SiteConversionRatePost from './posts/SiteConversionRatePost';
import ThoughtBarrierPost from './posts/ThoughtBarrierPost';
import UserExperienceCostsPost from './posts/UserExperienceCostsPost/UserExperienceCostsPost';
import WhySupportPost from './posts/WhySupportPost';
import PCI40 from './posts/PCI40';

import './Post.css';

function Post() {
	return (
		<>
			<Routes>
				<Route path="/benefits-of-discovery" element={<BenefitsDiscoveryPost />} />
				<Route path="/benefits-of-pwa" element={<BenefitsPWAPost />} />
				<Route path="/differences-between-it-and-applications-teams" element={<ITAppsPost />} />
				<Route path="/it-vs-applications-support" element={<ITvsAppsSupportPost />} />
				<Route path="/offshore-problems" element={<OffshoreProblemsPost />} />
				<Route path="/reactive-vs-proactive-development-support" element={<ReactiveProactivePost />} />
				<Route path="/should-build-mobile-app" element={<ShouldBuildMobileAppPost />} />
				<Route path="/site-conversion-rate" element={<SiteConversionRatePost />} />
				<Route path="/the-ins-and-outs-of-mobile-app-development" element={<MobileAppDevelopmentPost />} />
				<Route path="/the-thought-barrier" element={<ThoughtBarrierPost />} />
				<Route path="/user-experience-costs" element={<UserExperienceCostsPost />} />
				<Route path="/why-application-support" element={<WhySupportPost />} />
				<Route path="/pci-dss-4-0-compliance" element={<PCI40 />} />
			</Routes>
			<div className='cta-block'>
				<div className="cta-items">
					<ul>
						<li className='cta-action'>
							<h3>Ready to take your business to the next level?</h3>
							<p>Let us enhance your website to empower your brand and accelerate your evolution. Reach out today to get started.</p>
							<div className="cta-button"><a href="https://calendly.com/ross-assistedinnovations/30min" target="_blank" rel="noreferrer">Schedule a free consultation</a></div>
						</li>
					</ul>
				</div>
			</div>
		</>
	);
}

export default Post;