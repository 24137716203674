import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from './pages/Home';
import About from './pages/About';
import Approach from './pages/Approach';
import Blog from './pages/Blog';
import Contact from './pages/Contact';
import Post from './pages/Blog/Post';
import Services from './pages/Services';
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Portfolio from './pages/Portfolio';
import CaseStudies from "./pages/CaseStudies";
import Studies from "./pages/CaseStudies/Studies";

import Logo from './components/Logo';
import Navigation from './components/Navigation';
import ScrollToTop from './components/ScrollToTop';

import './App.css';

function App() {
	let currYear = new Date().getFullYear();

	return (
		<Router>
			<>
				<ScrollToTop />
				<header className="site-header-container">
					<div className="site-header">
						<Logo />
						<Navigation />
					</div>
				</header>
				<div className="site-content">
					<Routes>
						<Route path="/about" element={<About />} />
						<Route path="/approach" element={<Approach />} />
						<Route path="/privacy-policy" element={<PrivacyPolicy />} />
						<Route path="/services" element={<Services />} />
						<Route path="/contact" element={<Contact />} />
						<Route path="/blog" element={<Blog />} />
						<Route path="/blog/*" element={<Post />} />
						<Route path="/work/case-studies" element={<CaseStudies />} />
						<Route path="/work/case-studies/*" element={<Studies />} />
						<Route path="/work/portfolio" element={<Portfolio />} />
						<Route path="/" element={<Home />} />
					</Routes>
				</div>
				<div className='pre-footer'>
					<h2>Follow Our <strong><em>Innovation Space</em></strong></h2>
					<div className="pre-footer-content">
						<h3>Avoid the FOMO: Sign up to be included in our (roughly) monthly updates.</h3>
						<iframe title="Newsletter SIgn Up" src="https://embeds.beehiiv.com/f24c345d-8f8e-419b-b3d2-259286d323f3?slim=true" data-test-id="beehiiv-embed" height="52" frameborder="0" scrolling="no" style={{margin: 0, borderRadius: '0px !important', backgroundColor: 'transparent'}}></iframe>
					</div>
				</div>
				<div className="site-footer">
					<div className="copyright">&copy; {currYear} Assisted Innovations, LLC</div>
					<Navigation footer={true} />
				</div>
			</>
		</Router>
	);
}

export default App;
