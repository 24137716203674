import React, { useEffect } from 'react';

import Header from '../../components/Header';

import trackPage from '../../utils/trackPage';

import './Portfolio.css';

import allamericanheating from './allamericanheating.png';
import brenner from './brenner.png';
import lakecityplating from './lakecityplating.png';
import lazurite from './lazurite.png';
import magnolia from './magnolia.png';
import phipps from './phipps.png';
import stagepartners from './stagepartners.png';
import voicesofsepsis from './voicesofsepsis.png';
// import jaybro from './jaybro.png';
import cma from './cma.png';
import gsh from './gsh.png';
import hna from './hna.png';

function Portfolio() {
	useEffect(() => {
		document.title = "Built Success: Stories about Specialized Tool and Equipment Providers";
		trackPage();
	});

	return (
		<>
			<Header>
				<h1>Built Success</h1>
			</Header>
			<div className="page-body">
                <p>Our high-performance websites deliver solutions for our customers that go beyond aesthetics. They drive results, enhance customer experiences, and position our clients as leaders in their industries.</p>
                <p>Explore our portfolio and see how we've partnered with businesses like yours to turn challenges into opportunities and goals into achievements.</p>
                <section class="work-section">
					{/* <article>
						<img src={jaybro} />
					</article> */}
					<article>
						<a href="https://chasephipps.com" target="_blank" rel="noreferrer">
							<img src={phipps} alt="Chas E. Phipps website" />
							<h3>Proactive Website Management, Custom theme upgrades, Reactive support</h3>
						</a>
					</article>
					<article>
						<a href="https://brenner-fiedler.com" target="_blank" rel="noreferrer">
							<img src={brenner} alt="Brenner-Fiedler website" />
							<h3>WordPress FSE theme design and build</h3>
						</a>
					</article>
					<article>
						<a href="https://lakecityplating.com" target="_blank" rel="noreferrer">
							<img src={lakecityplating} alt="Lake City Plating website" />
							<h3>WordPress custom theme upgrade and rebuild</h3>
						</a>
					</article>
					<article>
						<a href="https://lazurite.co" target="_blank" rel="noreferrer">
							<img src={lazurite} alt="Lazurite website" />
							<h3>WordPress site updates and management</h3>
						</a>
					</article>
					<article>
						<a href="https://magnolia-medical.com/magnolia-analytics/" target="_blank" rel="noreferrer">	
							<img src={magnolia} alt="Magnolia Medical website" />
							<h3>WordPress custom blocks build and site management</h3>
						</a>
					</article>
					<article>
						<a href="https://voicesofsepsis.org/" target="_blank" rel="noreferrer">
							<img src={voicesofsepsis} alt="Voices of Sepsis website" />
							<h3>WordPress site build with custom blocks</h3>
						</a>
					</article>
					<article>
						<a href="https://yourstagepartners.com" target="_blank" rel="noreferrer">
							<img src={stagepartners} alt="Stage Partners website" />
							<h3>Proactive website managment, Magento 2 plugin development, Reactive support</h3>
						</a>
					</article>
					<article>
						<a href="https://gallaghersharp.com" target="_blank" rel="noreferrer">
							<img src={gsh} alt="Gallagher Sharp website" />
							<h3>WordPress custom theme development, custom blocks, site management</h3>
						</a>
					</article>
					<article>
						<a href="https://checkmyads.org" target="_blank" rel="noreferrer">
							<img src={cma} alt="Check My Ads website" />
							<h3>WordPress custom theme development, custom blocks, custom payment integration</h3>
						</a>
					</article>
					<article>
						<a href="https://allamericanheating.com" target="_blank" rel="noreferrer">
							<img src={allamericanheating} alt="All American Heating website" />
							<h3>WordPress custom theme development</h3>
						</a>
					</article>
					<article>
						<a href="https://heatherneill.com" target="_blank" rel="noreferrer">
							<img src={hna} alt="Heather Neill portfolio website" />
							<h3>WordPress custom theme development, custom blocks, site management</h3>
						</a>
					</article>
				</section>
			</div>
		</>
	);
}

export default Portfolio;