import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import Header from '../../../../components/Header';
import trackPage from '../../../../utils/trackPage';

function PCI40() {
	useEffect(() => {
		document.title = "Innovation Space | Finish Upgrading for PCI DSS 4.0 Compliance";
		trackPage();
	});

	return (
		<>
			<Header classes="article-header why-support-post">
				<h1>Finish Upgrading for PCI DSS 4.0 Compliance</h1>
			</Header>
			<div className="page-body post-body">
				<div className="step-containers">
					<article>
						<p>
							The clock is ticking for businesses that handle online payments. The latest Payment Card Industry Data Security Standard (PCI DSS) 4.0 requirements become mandatory on March 31, 2025. If your website processes, stores, or transmits credit card information, failing to comply with these new regulations could result in penalties, security vulnerabilities, or even loss of the ability to process payments.
						</p>
						<p>
							In the past - most small merchants use third party services (via iframes or "hosted fields") to avoid the need to go through PCI compliance scans every year. With 4.0 - though - there are requirements that you must meet even if you are using a third party hosted solution to accept payments.
						</p>
						<p>
							Here are some things that you should do before the deadline to ensure that you are in compliance:
						</p>
						<h2>Section 6.4.3</h2>
						<p>
							One of the biggest changes in PCI DSS 4.0 is the addition of section 6.4.3. This section requires that you have a documented process for managing the lifecycle of your payment software. This includes:
						</p>
						<ul>
							<li>Identifying all payment software in use</li>
							<li>Documenting the purpose of each software</li>
							<li>Documenting the software's lifecycle</li>
							<li>Documenting the software's end of life</li>
						</ul>
						<h3>How do you comply?</h3>
						<p>
							You will want to implement a Content-Security Policy if you haven't already. This policy should be as strict as is feasible to prevent malicious scripts from running on your site. This will help protect your customers from card skimmers and other malicious software.
						</p>
						<p>
							On top of the Content-Security Policy - you also need to create documentation for each script that is loaded on the payment pages of your site. A payment page is any page that can collect or display payment information. This documentation must include the purpose of the script, the lifecycle of the script, and the end of life for the script.
						</p>
						<h2>Section 11.4.1</h2>
						<p>
							Another key requirement in PCI DSS 4.0 is section 11.4.1. This section requires that you have a documented process for managing the lifecycle of your payment software. This expands the requirements of section 6.4.3 to include a process for monitoring and managing the lifecycle of all scripts on your site.
						</p>
						<h3>How do you comply?</h3>
						<p>
							Ideally this will involve automated monitoring of your site's scripts, with alerts to administrators if anything changes.
						</p>
						<p>
							If automated monitoring is not feasible - you will need to manually check your scripts, Content-Security Policy, and all security related headers for changes at least weekly.
						</p>
						<h2>Just the Start</h2>
						<p>
							These are just two of the many requirements in PCI DSS 4.0. The new standard is designed to help businesses better protect their customers' payment information. If you are not already working on upgrading your website to meet these new requirements, now is the time to start.
						</p>
						<h2>Assisted Innovations</h2>
						<p>
							The team at Assisted Innovations is here to help you implement the changes needed to meet PCI DSS 4.0 requirements. We can help you create a Content-Security Policy, document your scripts, and monitor your site for changes. We can also help you with other requirements in the new standard.
						</p>
						<p>
							If you aren't sure if you are in compliance - we offer an inexpensive Code Review service that can help you identify problems.
						</p>
						<p>
							For a code review, or to get help with your upgrades – <Link to="/contact">reach out</Link>.
						</p>
					</article>
				</div>
			</div>
		</>
	)
}

export default PCI40;